<template>
  <div class="mzuri-leads-widget">
    <div class="mzuri-leads__wrapper">
      <div class="vld-parent">
        <loading :active.sync="waiting"
                 :can-cancel="true"
                 :is-full-page="false"></loading>
        <b-container style="text-align:center" v-if="!expandedFinalStep">
          <b-row>
            <b-col cols="12">
              <template v-if="selectCity">
                <h4>Sprawdź za ile można wynająć mieszkanie. Pobierz raport!</h4>
              </template>
              <template v-else>
                <h4>Pobierz raport z poradami jak zwiększyć zysk z wynajmu.</h4>
              </template>
            </b-col>
          </b-row>
        </b-container>
        <b-container style="text-align: left;">
          <b-row class="justify-content-left">
            <b-col cols="12">
              <b-collapse v-model="expandedFirstStep">
                <div>
                  <b-form-group
                    v-if="selectCity"
                    id="selectCityGroup"
                    label="Miejscowość"
                    :invalid-feedback="miejscowoscError"
                    :state="showValidationErrors ? !miejscowoscError : null"
                  >
                    <multiselect
                      :disable="!cities"
                      id="selectCity"
                      v-model="formA.miejscowosc"
                      placeholder="Wybierz miejscowość z listy"
                      selectLabel="wybierz"
                      deselectLabel=""
                      selectedLabel="wybrane"
                      :options="cities" :searchable="true" :allow-empty="true">
                      <div slot="beforeLisst">
                        <div style="background: #eee; padding: 1rem;">
                        Nie ma Twojej miejscowości na liście?&nbsp; &nbsp; <b-button @click="disableCitySelection">Dalej &raquo;</b-button>
                        </div>
                      </div>
                      <div slot="pldaceholder">
                      </div>
                      <div slot="noResult">
                        <div style="font-size: 85%;">
                          <a style="text-decoration: underline; cursor: pointer;" @click="disableCitySelection">Nie ma mojej miejscowości na liście</a>
                        </div>
                      </div>
                    </multiselect>
                    <div style="margin-top: 0.5rem;font-size: 85%;">
                      <a style="text-decoration: underline; cursor: pointer;" @click="disableCitySelection">Nie ma mojej miejscowości na liście</a>
                    </div>
                  </b-form-group>
                  <div v-else>
                    <b-form-group
                      id="typeCityGroup"
                      label="Miejscowość"
                      description=""
                      :invalid-feedback="miejscowoscError"
                      :state="showValidationErrors ? !miejscowoscError : null"
                    >
                      <b-form-input v-model="formA.miejscowosc" type="text"></b-form-input>
                      <div style="margin-top: 0.5rem;font-size: 85%;">
                        <a style="text-decoration: underline; cursor: pointer;" @click="enableCitySelection">Wróć do wyboru miejscowości z listy</a>
                      </div>
                    </b-form-group>
                    <b-form-group
                      id="zipCodeGroup"
                      label="Kod pocztowy"
                      description=""
                    >
                      <b-form-input v-model="formA.kodpocztowy" type="text"></b-form-input>
                    </b-form-group>
                  </div>

                  <b-form-group
                    id="rooms"
                    label="Liczba pokoi"
                    description=""
                    :invalid-feedback="pokojeError"
                    :state="showValidationErrors ? !pokojeError : null"
                  >
                    <b-form-radio-group
                      v-model="formA.pokoje"
                      placeholder=""
                      text-field="label"
                      :options="listaPokoi">
                    </b-form-radio-group>
                    <div style="margin-top: 0.5rem;font-size: 85%;">
                      Jeżeli posiadasz dom lub mieszkanie z większa liczbą pokoi na wynajem skontaktuj się z nami pod adresem <a href="mailto:info@mzuri.pl">info@mzuri.pl</a>.
                    </div>
                  </b-form-group>
                  <b-form-group
                    id="standard"
                    label="Standard wykończenia"
                    description=""
                    :invalid-feedback="standardError"
                    :state="showValidationErrors ? !standardError : null"
                  >
                    <b-form-radio-group
                      v-model="formA.standard"
                      :options="listaStandardow"
                      text-field="label"
                      placeholder=""
                      >
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    id="furniture"
                    label="Lokal umeblowany?"
                    description=""
                    :invalid-feedback="umeblowaneError"
                    :state="showValidationErrors ? !umeblowaneError : null"
                  >
                    <b-form-radio-group
                      v-model="formA.umeblowane"
                      :options="listaUmeblowane"
                      placeholder=""
                      text-field="label"
                      name="mzuri-umeblowane">
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <b-button v-if="selectCity" id="generate-report" @click="generujRaport" class="mzuri-button mzuri-cta-button"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <title>file</title>
                  <path d="M11 15h1v-1h-1v1zM11 18h1v-1h-1v1zM11 12h1v-1h-1v1zM11 21h1v-1h-1v1zM11 24h1v-1h-1v1zM13 18h8v-1h-8v1zM13 12h8v-1h-8v1zM20.938 4h-13.938v24h18v-20l-4.062-4zM23 25.938h-14v-19.938h10v2.938h4v17zM13 21h8v-1h-8v1zM13 15h8v-1h-8v1zM13 24h8v-1h-8v1z"></path>
                </svg> Generuj raport </b-button>
                <b-button v-else id="request-report" @click="generujRaport" class="mzuri-button mzuri-cta-button"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <title>file</title>
                  <path d="M11 15h1v-1h-1v1zM11 18h1v-1h-1v1zM11 12h1v-1h-1v1zM11 21h1v-1h-1v1zM11 24h1v-1h-1v1zM13 18h8v-1h-8v1zM13 12h8v-1h-8v1zM20.938 4h-13.938v24h18v-20l-4.062-4zM23 25.938h-14v-19.938h10v2.938h4v17zM13 21h8v-1h-8v1zM13 15h8v-1h-8v1zM13 24h8v-1h-8v1z"></path>
                </svg> Pobierz raport z poradami dot. najmu </b-button>
                <b-popover target="generate-report" triggers="hover" placement="top">
                  Otrzymasz od nas raport z&nbsp;poradami jak zwiększyć zysk z&nbsp;wynajmu
                </b-popover>
                <b-popover target="request-report" triggers="hover" placement="top">
                  Otrzymasz od nas raport z&nbsp;poradami jak zwiększyć zysk z&nbsp;wynajmu
                </b-popover>
              </b-collapse>
              <b-collapse v-model="expandedSecondStep">
                <b-button variant="link" style="color: #888;" @click="edytujNieruchomosc">&laquo; wstecz</b-button>
                <h5 style="margin-top: 2rem;">Podaj dane kontaktowe, na które wyślemy przygotowany raport</h5>
                <b-form-group
                  label="Imię"
                  description=""
                  :invalid-feedback="imieError"
                  :state="showValidationErrors ? !imieError : null"
                >
                  <b-form-input v-model="formB.imie" type="text"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Nazwisko"
                  description=""
                  :invalid-feedback="nazwiskoError"
                  :state="showValidationErrors ? !nazwiskoError : null"
                >
                  <b-form-input v-model="formB.nazwisko" type="text"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="E-mail"
                  description="na podany adres wyślemy personalizowany raport"
                  :invalid-feedback="emailError"
                  :state="showValidationErrors ? !emailError : null"
                >
                  <b-form-input v-model="formB.email" type="text"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Telefon"
                  description=""
                  :invalid-feedback="phoneError"
                  :state="showValidationErrors ? !phoneError : null"
                >
                  <b-form-input v-model="formB.telefon" type="text"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-button @click="markConsents" size="sm" class="mzuri-button mzuri-button-small">Zaznacz wszystkie zgody</b-button>
                </b-form-group>
                <b-form-group
                  :invalid-feedback="zgoda1Error"
                  :state="showValidationErrors ? !zgoda1Error : null"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="formB.zgoda1"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value=""
                  >
                    <small>Wyrażam zgodę na przetwarzanie moich danych osobowych przez administratora danych w celu odpowiedzi na przesłane zapytanie. Administratorem danych jest Mzuri sp. z o.o. z siedzibą przy al. Niepodległości 221/1 w Warszawie (02-087). Podanie danych osobowych jest dobrowolne.</small>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :invalid-feedback="zgoda2Error"
                  :state="showValidationErrors ? !zgoda2Error : null"
                >
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="formB.zgoda2"
                    name="checkbox-2"
                    value="accepted"
                    unchecked-value=""
                  >
                    <small>Wyrażam zgodę na przetwarzanie moich danych osobowych w celu realizacji działań marketingowych administratora danych, zgodnie z ustawą z dnia 18.07.2002 r. o świadczeniu usług drogą elektroniczną (Dz.U.2017.1219). Administratorem danych jest Mzuri sp. z o.o. z siedzibą przy al. Niepodległości 221/1 w Warszawie (02-087).</small>
                  </b-form-checkbox>
                </b-form-group>
                &nbsp;&nbsp;<b-button @click="wyslijRaport" class="mzuri-button mzuri-cta-button"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <title>file</title>
                <path d="M11 15h1v-1h-1v1zM11 18h1v-1h-1v1zM11 12h1v-1h-1v1zM11 21h1v-1h-1v1zM11 24h1v-1h-1v1zM13 18h8v-1h-8v1zM13 12h8v-1h-8v1zM20.938 4h-13.938v24h18v-20l-4.062-4zM23 25.938h-14v-19.938h10v2.938h4v17zM13 21h8v-1h-8v1zM13 15h8v-1h-8v1zM13 24h8v-1h-8v1z"></path>
              </svg> Wyślij raport </b-button>
              </b-collapse>
              <b-collapse v-model="expandedFinalStep">
                <div class="confirmation">
                  <h4>Raport został wysłany na podany adres email.</h4>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPopover, BFormInput, BFormRadioGroup, BCollapse, BFormCheckbox, BFormGroup, BButton, BContainer, BRow, BCol,
} from 'bootstrap-vue';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
// import { db } from '../db';
// import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect';
import { validationMixin } from 'vuelidate';

const { required, email, minLength } = require('vuelidate/lib/validators');

const pokojeList = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

const standardyList = [
  { label: 'wysoki', value: 3 },
  { label: 'średni', value: 2 },
  { label: 'niski', value: 1 },
];

const umeblowaneList = [
  { label: 'Tak', value: 1 },
  { label: 'Nie', value: 2 },
];

const logEvent = function (category, action, label = '', value = '') {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'mzuriEvent',
    category,
    action,
    label,
    value,
  });
};

export default {
  name: 'MzuriLeads',
  mixins: [validationMixin],
  props: {
    msg: String,
    api: {
      type: String,
      required: true,
    },
  },
  validations: {
    formA: {
      miejscowosc: {
        required,
      },
      pokoje: {
        required,
      },
      standard: {
        required,
      },
      umeblowane: {
        required,
      },
    },
    formB: {
      imie: {
        required,
      },
      nazwisko: {
        required,
      },
      email: {
        required,
        email,
      },
      telefon: {
        required,
        minLength: minLength(9),
      },
      zgoda1: {
        required,
      },
    },
  },
  components: {
    Loading,
    BPopover,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BFormInput,
    BCollapse,
    BFormCheckbox,
    // bFormInput,
    // BFormSelect,
    BContainer,
    BRow,
    BCol,
    Multiselect,
  },
  data() {
    return {
      offerExists: false,
      final: null,
      showValidationErrors: false,
      selectCity: true,
      formA: {
        miejscowosc: '',
        kodpocztowy: '',
        standard: '',
        umeblowane: '',
        pokoje: '',
      },
      formB: {
        imie: '',
        nazwisko: '',
        email: '',
        telefon: '',
        zgoda1: '',
        zgoda2: '',
      },
      cities: [],
      listaPokoi: [],
      listaStandardow: [],
      listaUmeblowane: [],
      showPersonalDetails: false,
      waiting: false,
      expandedFirstStep: true,
      expandedSecondStep: false,
      expandedFinalStep: false,
      innerState: 'step1',
    };
  },
  computed: {
    state: {
      get() {
        return this.innerState;
      },
      set(state) {
        if (state === 'step2') {
          this.expandedSecondStep = true;
          this.expandedFirstStep = false;
        }
        if (state === 'step1') {
          this.expandedSecondStep = false;
          this.expandedFirstStep = true;
        }
        if (state === 'final') {
          this.expandedSecondStep = false;
          this.expandedFirstStep = false;
          this.expandedFinalStep = true;
        }
        if (state !== this.innerState) {
          this.showValidationErrors = false;
        }
        this.innerState = state;
      },
    },
    miejscowoscError() {
      if (!this.$v.formA.miejscowosc.required) {
        return 'Wybierz lub wprowadź nazwę miejscowości';
      }
      return '';
    },
    pokojeError() {
      if (!this.$v.formA.pokoje.required) {
        return 'Wybierz liczbę pokoi';
      }
      return '';
    },
    standardError() {
      if (!this.$v.formA.standard.required) {
        return 'Wybierz standard wykończenia nieruchomości';
      }
      return '';
    },
    umeblowaneError() {
      if (!this.$v.formA.umeblowane.required) {
        return 'Wypełnij pole';
      }
      return '';
    },
    imieError() {
      if (!this.$v.formB.imie.required) {
        return 'Wypełnij pole';
      }
      return '';
    },
    nazwiskoError() {
      if (!this.$v.formB.nazwisko.required) {
        return 'Wypełnij pole';
      }
      return '';
    },
    emailError() {
      if (!this.$v.formB.email.required || !this.$v.formB.email.email) {
        return 'Wprowadź poprawny adres e-mail';
      }
      return '';
    },
    phoneError() {
      if (!this.$v.formB.telefon.required || !this.$v.formB.telefon.minLength) {
        return 'Wprowadź poprawny numer telefonu';
      }
      return '';
    },
    zgoda1Error() {
      if (this.$v.formB.zgoda1 && !this.$v.formB.zgoda1.required) {
        return 'Zgoda jest wymagana';
      }
      return '';
    },
    zgoda2Error() {
      if (this.$v.formB.zgoda2 && !this.$v.formB.zgoda2.required) {
        return 'Zgoda jest wymagana';
      }
      return '';
    },
  },
  methods: {
    markConsents() {
      this.formB.zgoda1 = 'accepted';
      this.formB.zgoda2 = 'accepted';
      logEvent('formularz jakiczynsz.pl', 'Kliknięcie: Zaznacz wszystkie zgody');
    },
    wyslijRaport() {
      this.$v.$touch();
      if (this.$v.formB.$invalid) {
        this.showValidationErrors = true;
        return;
      }
      this.waiting = true;

      axios.post(`${this.api}/lead/`, {
        estate: {
          city: this.formA.miejscowosc,
          zip: this.formA.kodpocztowy,
          rooms: this.formA.pokoje,
          furniture: this.formA.umeblowane,
          standard: this.formA.standard,
        },
        lead: {
          firstname: this.formB.imie,
          lastname: this.formB.nazwisko,
          email: this.formB.email,
          phone: this.formB.telefon,
          consent1: this.formB.zgoda1,
          consent2: this.formB.zgoda2,
        },
      }).then((response) => {
        this.offerExists = response.data.offer;
        this.state = 'final';
        this.final = response;
        logEvent('formularz jakiczynsz.pl', 'Kliknięcie: Wyślij raport', 'Sukces');
      }).catch(() => {
        logEvent('formularz jakiczynsz.pl', 'Kliknięcie: Wyślij raport', 'Błąd');
      }).finally(() => {
        this.waiting = false;
      });
    },
    edytujNieruchomosc() {
      this.state = 'step1';
    },
    generujRaport() {
      this.$v.$touch();
      if (this.$v.formA.$invalid) {
        this.showValidationErrors = true;
        return;
      }
      this.state = 'step2';
      this.showPersonalDetails = true;
      logEvent('formularz jakiczynsz.pl', 'Kliknięcie: Generuj raport', this.formA.miejscowosc);
    },
    disableCitySelection() {
      this.selectCity = false;
      this.formA.miejscowosc = '';
    },
    enableCitySelection() {
      this.formA.kodpocztowy = '';
      this.formA.miejscowosc = '';
      this.selectCity = true;
      logEvent('formularz jakiczynsz.pl', 'Kliknięcie: Nie ma miejscowości na liście');
    },
    onSearch(search, loading) {
      loading(true);
      this.search(loading, search, this);
    },
    /*
    search: debounce((loading, search, vm) => {
      console.log('search', search, vm);
      axios.get(
        `https://api.github.com/search/repositories?q=${escape(search)}`,
      ).then((res) => {
        vm.$set('options', res.data.items);
      }).finally(() => {
        loading(false);
      });
    }, 350),
    */
  },
  mounted() {
    axios.get(`${this.api}/cities-static/`).then((response) => {
      this.cities = response.data.miasta;
    });
    // this.cities = citiesList;
    this.listaPokoi = pokojeList;
    this.listaStandardow = standardyList;
    this.listaUmeblowane = umeblowaneList;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  // $zindex-popover: 100;
  .multiselect__element {
    margin: 0 !important;
  }
  .multiselect {
    input {
      box-shadow: none !important;
    }
    input:focus {
      box-shadow: none !important;
    }
  }
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .mzuri-leads-widget {
    @import "~bootstrap/scss/root";
    @import "~bootstrap/scss/reboot";
    @import "~bootstrap/scss/type";
    @import "~bootstrap/scss/images";
    @import "~bootstrap/scss/code";
    @import "~bootstrap/scss/grid";
    // @import "~bootstrap/scss/tables";
    @import "~bootstrap/scss/forms";
    @import "~bootstrap/scss/buttons";
    @import "~bootstrap/scss/transitions";
    @import "~bootstrap/scss/dropdown";
    // @import "~bootstrap/scss/button-group";
    // @import "~bootstrap/scss/input-group";
    @import "~bootstrap/scss/custom-forms";
    // @import "~bootstrap/scss/nav";
    // @import "~bootstrap/scss/navbar";
    // @import "~bootstrap/scss/card";
    // @import "~bootstrap/scss/breadcrumb";
    // @import "~bootstrap/scss/pagination";
    // @import "~bootstrap/scss/badge";
    // @import "~bootstrap/scss/jumbotron";
    // @import "~bootstrap/scss/alert";
    // @import "~bootstrap/scss/progress";
    // @import "~bootstrap/scss/media";
    // @import "~bootstrap/scss/list-group";
    // @import "~bootstrap/scss/close";
    // @import "~bootstrap/scss/toasts";
    // @import "~bootstrap/scss/modal";
    // @import "~bootstrap/scss/tooltip";
    // @import "~bootstrap/scss/carousel";
    // @import "~bootstrap/scss/spinners";
    @import "~bootstrap/scss/utilities";
    // @import "~bootstrap/scss/print";
    /*
    @import "~bootstrap/dist/css/bootstrap.min";
    @import "~bootstrap-vue/dist/bootstrap-vue";
    @import "~vue-loading-overlay/dist/vue-loading";
    */
    @import "~bootstrap-vue/dist/bootstrap-vue";
    @import "~vue-loading-overlay/dist/vue-loading";
    .custom-control-label {
      display: flex;
      align-items: center;
    }
    .mzuri-button {
      background-color:#f37321;
      border-color: #e86818;
      &:hover {
        background-color:#e86818 !important;
        border-color: #e86818;
      }
    }
    .mzuri-button-small {
      padding: 0.1rem 0.75rem;
    }
    .mzuri-cta-button {
      display: flex; align-items:center; fill: white;
      font-weight: bold;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  @import "~bootstrap/scss/popover";
  .mzuri-leads-widget {
    .custom-control-inline {
      margin-right: 2rem;
    }
    .custom-control-label {
      display: flex;
      align-items: center;
    }
    .form-group {
      margin-bottom: 1.5rem !important;
    }
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .confirmation {
    text-align: center;
  }
  .col-form-label {
    margin-top: 10px !important;
    font-weight: bold;
    display: block;
  }
  .custom-control-label small {
    line-height: 1.3 !important;
  }
</style>
