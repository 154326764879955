<template>
  <div id="app">
    <!--<mzuri-leads api="http://localhost:5000/api"/>-->
    <mzuri-leads api="https://mzurileads.firebaseapp.com/api"/>
  </div>
</template>

<script>
import MzuriLeads from './components/MzuriLeads.vue';

export default {
  name: 'app',
  components: {
    MzuriLeads,
  },
};
</script>

<style lang="scss">
#dapp {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
